import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
  DnxModal,
  DnxButton,
  DnxProgress
} from "core/utils/dnxCustomElementsReactLoader";
import SessionTimeout from "./SessionTimeout";
import { signout } from "core/utils/auth";
import i18n from "amdi18n-loader!./nls/i18_sessionTimeOut";
import i18nUtil from "core/utils/i18n";
import axios from "axios";
import alertBoxStyle from "./modelalert.less";
import broadcastchannel from "./BroadcastChannel";
const modalNode = document.createElement("div");
document.querySelector("body").appendChild(modalNode);
class IdleSessionTimeout extends Component {
  session = null;
  timeLeft = 0;
  idleTimeOut = 60 * 60000; // minute to millisec
  showTimerDialog = 5 * 60000; // minute to millisec
  autoShowTimerDialog = 0;
  autoIdleEndSession = 0;
  autoIdleExtendSession = 0;
  idleTimer = null;

  state = {
    modalflag: false,
    minutes: 0,
    seconds: 0
  };

  componentDidMount() {
    this.channel = broadcastchannel;
    this.channel.onmessage = (e) => {
      const { action } = e.data || e;
      const { timeleft } = e.data || e;
      if (action == "resetSessionTime") {
        this.getSessionTimeOutMinutes();
      }
      if (action == "showDialog") {
        this.autoShowTimerDialog = 1;
        this.onTimeLeftChange(timeleft);
        this.autoShowTimerDialog = 0;
      } else if (action == "endSession") {
        this.autoIdleEndSession = 1;
        if (e.data.timeLeft != undefined) {
          this.timeLeft = e.data.timeLeft ;
        }
        this._endSession();
        this.autoIdleEndSession = 0;
      } else if (action == "extendSession") {
        this.autoIdleExtendSession = 1;
        this._extendSession();
        this.autoIdleExtendSession = 0;
      }
    };
    this.getSessionTimeOutMinutes();
  }

  getSessionTimeOutMinutes = async() => {
    const URL = "/api/system/v1/identitymgmt/tenant-preference";
    await axios
      .get(URL)
      .then((res) => this.startBroadCast(res.data.sessionTimeOutMinutes))
      .catch((error) => {
        this.startBroadCast(60);
      });
  };

  startBroadCast = (sessionTimeOutMinutes) => {
    this.idleTimeOut = sessionTimeOutMinutes * 60000; // minute to millisec
    this.showTimerDialog = (sessionTimeOutMinutes - 5) * 60000; // minute to millisec
    this.trigger();
  };

  trigger = () => {
    this.session = new SessionTimeout(this.idleTimeOut, this.showTimerDialog);
    this.session.init();
    this.session.onTimeOut = this.onTimeOut.bind(this);
    this.session.onTimeLeftChange = this.onTimeLeftChange.bind(this);
  };
  onTimeOut = () => {
    if (this._timerId !== undefined) {
      window.clearTimeout(this._timerId);
    }
    signout();
  };
  getTime = (milliseconds) => {
    const time = { minutes: "", seconds: "" };
    if (milliseconds != null) {
      time.minutes = parseInt((milliseconds / (1000 * 60)) % 60, 10);
      time.seconds = (`0${parseInt((milliseconds / 1000) % 60, 10)}`).slice(-2);
    }
    return time;
  };
  onTimeLeftChange = (timeLeft) => {
    this.idleExtendSession = 0;
    this.idleEndSession = 0;
    this.timeLeft = timeLeft;
    const { minutes, seconds } = this.getTime(this.timeLeft);
    this.setState({ modalflag: true, minutes, seconds });
    this.session.dispose();
    this._timerId = window.setInterval(this._onTimeOut, 1000);
    if (this.autoShowTimerDialog == 0) {
      this.channel.postMessage({
        action: "showDialog",
        timeleft: this.timeLeft
      });
    }
  };
  _endSession = () => {
    this.setState({ modalflag: false });
    if (this.autoIdleEndSession == 0) {
      this.channel.postMessage({ action: "endSession" });
    }
    this.onTimeOut();
  };
  _extendSession = () => {
    if (this._timerId !== undefined) {
      window.clearTimeout(this._timerId);
    }
    this.setState({ modalflag: false });
    if (this.autoIdleExtendSession == 0) {
      this.session.init();
      this.channel.postMessage({ action: "extendSession" });
    }
  };
  _onTimeOut = () => {
    this.timeLeft -= 1000;
    if (this.timeLeft <= 0) {
      window.sessionStorage.setItem("idleTimerLoggedOut", true);
      this.onTimeOut();
    }
    const { minutes, seconds } = this.getTime(this.timeLeft);
    let modalflag = true;
    if (seconds < 0) {
      modalflag = false;
    }
    this.setState({ modalflag, minutes, seconds });
  };
  render() {
    const { modalflag, minutes, seconds } = this.state;
    let modal = null;
    const message = i18nUtil.getFilledTextForHBS(minutes > 0 ? i18n.timermessage : i18n.timermessage_showSeconds, {
      minutes,
      seconds
    });
    const pb = ((minutes * 60000 + seconds * 1000) / this.showTimerDialog) * 100;

    if (modalflag) {
      modal = (
        <DnxModal noheader width="600px" height="400px" closer={false}>
          <div
            className={"dnx--css-modal_content"}
            style={{ height: "auto", marginLeft: "36px", marginRight: "36px" }}
          >
            <div className={alertBoxStyle.icon_st}>
              <span
                className="dnac-icon-warning-filled-sm"
                style={{
                  color: "#ffa000",
                  fontSize: "36px",
                  cursor: "pointer"
                }}
              ></span>
            </div>
            <div className={alertBoxStyle.icon_text_st}>{i18n.timeoutwarning}</div>
            <div className={alertBoxStyle.content_st}>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#545454"
                }}
              >
                {i18n.timermessage1}
              </p>
              <p>{i18n.timermessage2}</p>
              <div
                style={{
                  padding: "15px 0 0 65px",
                  width: "85%",
                  margin: "0 auto",
                  textAlign: "left"
                }}
              >
                <DnxProgress
                  value={pb}
                  nocancel="true"
                  indicator={false}
                ></DnxProgress>
                {message}
              </div>
            </div>
          </div>
          <div className="cta-bar" style={{ textAlign: "center" }}>
            <DnxButton
              name="endSession"
              label={i18n.endsession}
              flavor="link"
              click={this._endSession}
            ></DnxButton>
            <DnxButton
              name="extendSession"
              label={i18n.extendsession}
              flavor="primary"
              click={this._extendSession}
            ></DnxButton>
          </div>
        </DnxModal>
      );
    } else {
      modal = <span style={{ display: "none" }}/>;
    }
    return <div>{modal}</div>;
  }
}
export const init = () => {
  ReactDOM.render(<IdleSessionTimeout/>, modalNode);
};
