(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("amcharts.themes.light"), require("amcharts.responsive"), require("amcharts.export"), require("amcharts.serial"), require("amcharts.pie"), require("amcharts.xy"), require("jqueryui"), require("amcharts"), require("mapbox"), require("mapboxLabel"), require("mapboxMarkerCluster"));
	else if(typeof define === 'function' && define.amd)
		define(["amcharts.themes.light", "amcharts.responsive", "amcharts.export", "amcharts.serial", "amcharts.pie", "amcharts.xy", "jqueryui", "amcharts", "mapbox", "mapboxLabel", "mapboxMarkerCluster"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("amcharts.themes.light"), require("amcharts.responsive"), require("amcharts.export"), require("amcharts.serial"), require("amcharts.pie"), require("amcharts.xy"), require("jqueryui"), require("amcharts"), require("mapbox"), require("mapboxLabel"), require("mapboxMarkerCluster")) : factory(root["amcharts.themes.light"], root["amcharts.responsive"], root["amcharts.export"], root["amcharts.serial"], root["amcharts.pie"], root["amcharts.xy"], root["jqueryui"], root["amcharts"], root["mapbox"], root["mapboxLabel"], root["mapboxMarkerCluster"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, function(__WEBPACK_EXTERNAL_MODULE__135__, __WEBPACK_EXTERNAL_MODULE__136__, __WEBPACK_EXTERNAL_MODULE__137__, __WEBPACK_EXTERNAL_MODULE__258__, __WEBPACK_EXTERNAL_MODULE__324__, __WEBPACK_EXTERNAL_MODULE__325__, __WEBPACK_EXTERNAL_MODULE__493__, __WEBPACK_EXTERNAL_MODULE__646__, __WEBPACK_EXTERNAL_MODULE__891__, __WEBPACK_EXTERNAL_MODULE__893__, __WEBPACK_EXTERNAL_MODULE__894__) {
return 